import { GoogleGenerativeAI } from "@google/generative-ai";

// Initialize the GoogleGenerativeAI instance with your API key
const genAI = new GoogleGenerativeAI("AIzaSyBePHy0bpg4U55QMbF5l4s0t-IkUvOJt1c"); // Replace with your actual API key

/**
 * Query the Gemini API with the experimental `gemini-2.0-flash-exp` model.
 * @param {string} prompt - The user's input or question.
 * @returns {Promise<string>} - The generated response from Gemini API.
 */
export const queryGemini = async (prompt) => {
  try {
    console.log("Sending prompt to Gemini API:", prompt);

    // Configure the experimental model and generation settings
    const generationConfig = {
      temperature: 1,
      top_p: 0.95,
      top_k: 40,
      max_output_tokens: 8192,
    };

    const model = genAI.getGenerativeModel({
      model: "gemini-2.0-flash-exp",
      generationConfig: generationConfig,
    });

    // Start a chat session with the model
    const chatSession = model.startChat({ history: [] });

    // Send the user's prompt and get the response
    const response = await chatSession.sendMessage(prompt);

    console.log("Gemini API Response:", response);

    // Extract the correct response text
    if (response && response.response && response.response.text) {
      return response.response.text; // Adjust if necessary based on the response structure
    }

    throw new Error("Invalid response format from Gemini API.");
  } catch (error) {
    console.error("Error querying Gemini API:", error.response?.data || error.message || error);
    throw new Error("Failed to query Gemini API. Please check your network or API key.");
  }
};

/**
 * List available models from the Gemini API.
 * Returns an empty array if fetching models fails.
 */
export const listAvailableModels = async () => {
  try {
    const models = await genAI.listModels();
    console.log("Available Models:", models);
    return models;
  } catch (error) {
    console.error("Error listing models:", error.response?.data || error.message || error);
    return []; // Return an empty array if the call fails
  }
};
