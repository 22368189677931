import React, { useState, useEffect } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode"; // Correctly imported as a named export
import { queryGemini, listAvailableModels } from "./geminiService";
import "./App.css";

const App = () => {
  const [user, setUser] = useState(null); // Store logged-in user info
  const [question, setQuestion] = useState(""); // Store user question
  const [response, setResponse] = useState(""); // Store Gemini API response
  const [error, setError] = useState(""); // Store error messages for display
  const [availableModels, setAvailableModels] = useState([]); // Store available models

  // Fetch available models on component mount
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const models = await listAvailableModels();
        setAvailableModels(models);
      } catch (err) {
        console.error("Error fetching available models:", err);
        setAvailableModels([]); // Proceed with an empty list if the call fails
        setError(""); // Do not block functionality with an error message here
      }
    };

    fetchModels();
  }, []);

  // Handle successful Google login
  const handleLoginSuccess = (credentialResponse) => {
    console.log("Login Success Credential Response:", credentialResponse);
    try {
      const userInfo = jwtDecode(credentialResponse.credential); // Decode JWT token
      console.log("Decoded User Info:", userInfo);
      setUser(userInfo);
    } catch (err) {
      console.error("Error decoding user info:", err);
      setError("Failed to decode user information. Please try again.");
    }
  };

  // Handle login failure
  const handleLoginFailure = () => {
    console.error("Login Failed");
    setError("Google login failed. Please try again.");
  };

  // Submit a question to Gemini API
  const handleQuestionSubmit = async () => {
    console.log("Submitting question to Gemini API:", question);
    setError(""); // Clear previous errors
    try {
      const res = await queryGemini(question);
      setResponse(res || "No response received from Gemini API.");
    } catch (err) {
      console.error("Error querying Gemini API:", err);
      setError("Failed to query Gemini API. Please check your network or API key.");
      setResponse("");
    }
  };

  return (
    <div className="app-container">
      <h1>React App with Google Login and Gemini AI</h1>

      {/* Google login or display user info */}
      {!user ? (
        <GoogleLogin onSuccess={handleLoginSuccess} onError={handleLoginFailure} />
      ) : (
        <div className="user-info">
          <img
            src={user.picture}
            alt={user.name}
            style={{ width: 50, height: 50, borderRadius: "50%", marginRight: 10 }}
          />
          <h2>Welcome, {user.name}!</h2>
        </div>
      )}

      {/* Display available models or an unavailable message */}
      {user && (
        <div className="models-list">
          <h3>Available Models:</h3>
          {availableModels.length > 0 ? (
            <ul>
              {availableModels.map((model, index) => (
                <li key={index}>{model.model}</li>
              ))}
            </ul>
          ) : (
            <p>Model listing is currently unavailable. You can still query Gemini AI.</p>
          )}
        </div>
      )}

      {/* Input and submit question */}
      {user && (
        <div className="interaction-area">
          <textarea
            rows="4"
            placeholder="Ask a question to Gemini AI..."
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          <button onClick={handleQuestionSubmit}>Ask Gemini AI</button>

          {/* Display error messages */}
          {error && <div className="error-message">{error}</div>}

          {/* Display Gemini API response */}
          {response && (
            <div className="gemini-response">
              <h3>Gemini AI Response:</h3>
              <p>{response}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default App;
